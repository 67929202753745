<template>
  <div class="panel-container">
    <aside :class="gift.length === 1 && 'one-gift-title'">
      <div v-if="gift.length !== 1" class="change-prize-left cursor-pointer" @click="changeAward(nowGift.awardIndex, 'close')"></div>
      <span class="text-liner pre-item-title oneline-overflow">{{ nowGift?.title }}</span>
      <div v-if="gift.length !== 1" class="change-prize-right cursor-pointer" @click="changeAward(nowGift.awardIndex, 'add')"></div>
    </aside>
    <p class="prize-name text-liner oneline-overflow">{{ nowGift?.award_name }}</p>
    <!-- 当前奖品图片 -->
    <img v-if="nowGift?.picture" class="gift_img" :src="nowGift.picture">
    <img v-else class="gift_img" :src="normalPic">
    <div class="account text-liner">剩余数量: {{ lastNumber }}</div>
    <div class="prize-count">
      <div class="prize-count-content">
        <span class="cursor-pointer"  @click="showAllInDrawDialog">抽奖参与人数</span>
        <div class="in-draw">
          <el-input-number v-model="allPeople" disabled :controls="false" />
          <div class="cursor-pointer refresh" @click="refresh"></div>
        </div>
      </div>
    </div>
    <div class="prize-count">
      <div class="prize-count-content">
        <span>每次抽取人数</span>
        <div class="in-draw">
          <div class="cursor-pointer refresh-left reduce-btn" :class="(selPeopleNum === 1) && 'disabled'" @click="changeExtract('close')"></div>
          <el-input-number v-model="selPeopleNum" :min="1" :max="lastNumber > inDrowNumber ? inDrowNumber : lastNumber" :controls="false" />
          <div class="cursor-pointer add-btn" :class="(selPeopleNum >= allPeople || selPeopleNum >= lastNumber) && 'disabled'" @click="changeExtract('add')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, reactive, toRefs, watch } from 'vue'
import normalPic from '@/assets/img/prize/gift-icon.png'
import { useStore } from 'vuex'

export default {
  name: 'LeftPanel',
  props: {
    inDrowNumber: { // 总参与人总数
      type: Number,
      default: 0
    },
    prizeData: { // 抽奖详情
      type: Object,
      default: () => {}
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const gift = computed(() => store.state.prize.prizelist)
    const state = reactive({
      count: 1,
      allPeople: computed(() => props.inDrowNumber),
      prizeData: computed(() => props.prizeData),
      selPeopleNum: 1,
      nowGift: gift.value[0]
    })
    const lastNumber = computed(() => state.nowGift.all_num - state.nowGift.used_num)
    const methods = {
      changeAward (index, operRate) { // 切换数据
        let queryIndex
        operRate === 'add' ? (queryIndex = index === gift.value.length - 1 ? 0 : index + 1) : (queryIndex = index === 0 ? gift.value.length - 1 : index - 1)
        state.nowGift = gift.value.find(i => i.awardIndex === queryIndex)
        store.commit('prize/setNowGift', state.nowGift) // 存储当前奖品
        state.selPeopleNum = 1
        state.selPeopleNum > state.allPeople && (state.selPeopleNum = state.allPeople)
        const lastNum = state.nowGift.all_num - state.nowGift.used_num
        state.selPeopleNum > lastNum && (state.selPeopleNum = lastNum)
        store.commit('prize/setPrizeConditions', { allNumber: lastNum, allPeople: state.allPeople })
        emit('changeAward')
      },
      showAllInDrawDialog () { // 显示参与人数
        emit('showAllInDrawDialog')
      },
      changeExtract (type) { // 设置每次抽取总数
        if (type === 'add' && state.selPeopleNum < state.allPeople && state.selPeopleNum < lastNumber.value) {
          state.selPeopleNum += 1
        } else if (type === 'close' && state.selPeopleNum > 1) {
          state.selPeopleNum -= 1
        }
      },
      refresh () {
        emit('refresh')
      }
    }

    watch(() => state.selPeopleNum, (peopleNum) => {
      store.commit('prize/setSelNumber', peopleNum)
    }, { immediate: true })

    // 监听奖品抽取数量改变 自动修改相关数据
    watch(() => [gift.value, state.allPeople], ([newGift, newPeople]) => {
      state.nowGift = newGift[state.nowGift.awardIndex]
      state.selPeopleNum > state.allPeople && (state.selPeopleNum = state.allPeople)
      const lastNum = state.nowGift.all_num - state.nowGift.used_num
      state.selPeopleNum > lastNum && (state.selPeopleNum = lastNum)
      store.commit('prize/setPrizeConditions', { allNumber: lastNum, allPeople: state.allPeople })
    }, { deep: true, immediate: true })

    onMounted(() => {
      store.commit('prize/setNowGift', state.nowGift) // 设置首次
    })

    return {
      ...toRefs(state),
      ...methods,
      normalPic,
      gift,
      lastNumber
    }
  }
}
</script>

<style lang="less" scoped>
.after-bg {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
}
.panel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}
aside {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 42px;
  height: 60px;
  line-height: 60px;
}
.one-gift-title {
  justify-content: center;
}
.prize-name {
  width: 100%;
  margin-top: 20px;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: 600;
  text-align: center;
}
.change-prize-left,
.change-prize-right {
  width: 40px;
  height: 40px;
}
.change-prize-left {
  background: url('../../../../assets/img/prize/add-left-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-left-hover.svg');
    background-size: 100% 100%;
  }
}
.change-prize-right {
  background: url('../../../../assets/img/prize/add-right-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-right-hover.svg');
    background-size: 100% 100%;
  }
}
.gift_img {
  width: 254px;
  height: 254px;
  margin-top: 7px;
  object-fit: cover;
}
.account {
  position: relative;
  text-align: center;
  font-weight: 600;
  font-size: 28px;
  height: 52px;
  line-height: 52px;
  min-width: 210px;
  margin-top: 20px;
  &::after {
    .after-bg();
    background: url('../../../../assets/img/prize/count_bg.svg');
    background-size: 100% 100%;
  }
}
.prize-count {
  width: 100%;
  height: 108px;
  position: relative;
  padding-top: 28px;
  &:first-child {
    margin-top: 10px;
  }
  &::before {
    .after-bg();
    background: url('../../../../assets/img/prize/plate_bg.png');
    background-size: 100% 100%;
  }
  &:last-child {
    transform: translateY(-8px)
  }
  .prize-count-content {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    line-height: 100%;
    font-size: 20px;
    font-weight: 600;
    color: #134A97;
    padding: 28px 25px 0 27px;
    div.in-draw {
      display: flex;
      align-items: center;
      .refresh, .add-btn, .reduce-btn {
        width: 34px;
        height: 34px;
        margin-left: 8px;
      }
      :deep(.el-input) {
        height: 34px;
        line-height: 34px;
      }
      .refresh {
        background: url('../../../../assets/img/prize/recover-icon.png');
        background-size: 100% 100%;
        &:hover {
          background: url('../../../../assets/img/prize/recover-icon-hover.png');
          background-size: 100% 100%;
        }
      }
      .add-btn {
        background: url('../../../../assets/img/prize/add-icon.png');
        background-size: 100% 100%;
        &:hover {
          background: url('../../../../assets/img/prize/add-icon-hover.png');
          background-size: 100% 100%;
        }
      }
      .reduce-btn {
        background: url('../../../../assets/img/prize/reduce-icon.png');
        background-size: 100% 100%;
        &:hover {
          background: url('../../../../assets/img/prize/reduce-icon-hover.png');
          background-size: 100% 100%;
        }
      }
      .refresh-left {
        margin-right: 16px;
        margin-left: 0;
      }
    }
  }
}
:deep(.el-input-number) {
  min-width: 38px;
  width: 58px;
  border-radius: 8px;
  overflow: hidden;
  height: 38px;
  line-height: 38px;
  background: rgba(60, 147, 255, 0.4);
  box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.3), inset 0 2px 4px #617EE8;
}
:deep(.el-input-number.is-without-controls .el-input__inner) {
  background: linear-gradient(180deg, #FFFFFF 23.08%, #AFECFF 82.05%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-align: center;
  border: none;
  color: #fff;
  font-size: 26px;
  padding: 7px 0;
}
.pre-item-title {
  letter-spacing: 0.1em;
}
</style>
