export const setBg = (canvasEl) => {
  const HEIGHT = document.documentElement.clientHeight
  const WIDTH = document.documentElement.clientWidth
  const particles = []
  let ctx = ''
  class Particle {
    id = 0
    x = 0
    y = 0
    radius = 0
    color = ''
    img = ''

    /**
     * @param id 粒子的 id，用于标识粒子
     * @param x 粒子的 x 坐标
     * @param y 粒子的 y 坐标
     * @param radius 粒子的半径大小
     */
    constructor (id, x, y, radius, img) {
      this.id = id
      this.x = x
      this.y = y
      this.radius = radius
      this.img = img
    }

    /**
     * @description 绘制粒子
     * @param ctx canvas 2d 上下文对象
     */
    draw () {
      ctx.drawImage(this.img, this.x, this.y, 42, 321)
    }

    /**
     * @description 粒子移动 只在垂直方向上移动
     * @param boundaryHeight 垂直方向的边界 用于超出边界时移动到边界另一边
     */
    move (particleMoveRate) {
      this.y -= particleMoveRate
      if (this.y <= -this.radius) {
        this.y = HEIGHT + this.radius
      }

      this.draw()
    }
  }
  /**
   * 获取随机数
   * @param {*} x 下限
   * @param {*} y 上限
   * @returns 随机数
   */
  function getRandom (x, y) {
    return parseInt(Math.random() * (y - x + 1) + x)
  }
  /**
   * @description 初始化 canvas 画布
   * @param canvasEl canvas 元素
   */
  const setupCanvasEl = (canvasEl = document.querySelector('#prize-bg')) => {
    // console.log(canvasEl)
    // 获取上下文对象
    ctx = canvasEl.getContext('2d')
    canvasEl.height = HEIGHT
    canvasEl.width = WIDTH
  }

  /**
   * @description 生成粒子
   * @param img 图片的地址
   * @param ctx canvas 2d 上下文对象
   * @param particleCount 要生成的粒子数量
   */
  const generateParticles = (src, particleCount, particleMaxSize) => {
    const img = new Image()
    img.src = src

    img.onload = function () {
      for (let i = 0; i < particleCount; i++) {
        // 粒子 x, y 坐标
        const x = Math.random() * WIDTH
        const y = Math.random() * HEIGHT

        // 粒子的大小在 1 ~ particleMaxSize 个单位之间随机生成
        const radius = Math.random() * (particleMaxSize - 1) + 1

        const particle = new Particle(i, x, y, radius, this)

        particles.push(particle)

        particle.draw()
      }
    }
  }

  /**
   * @description 开启粒子动画
   */
  const showParticleAnimation = (particleMoveRate) => {
    // 清屏 用于刷新下一帧
    ctx.clearRect(0, 0, WIDTH, HEIGHT)

    // console.log(particles)
    for (const particle of particles) {
      particle.move(particleMoveRate)
    }

    requestAnimationFrame(showParticleAnimation.bind(null, particleMoveRate))
  }
  const particleCount = getRandom(10, 15)
  const particleMaxSize = 600
  const particleMoveRate = 2.5

  // 初始化 canvas 信息
  setupCanvasEl(canvasEl)

  // 生成粒子
  generateParticles('https://static-sns.imyfone.cn/uploads/images/2023/04/light.png', particleCount, particleMaxSize)

  // 开启粒子动画
  showParticleAnimation(particleMoveRate)
}
