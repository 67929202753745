<template>
  <el-dialog show-close :custom-class="`prize-dialog ${className} ${prizeType}`" destroy-on-close :close-on-click-modal="!animation">
      <slot name="contents">
        <template v-if="prizeType === 'avatar'">
          <div v-if="btnText === BTN_VALUE.pouse || (selNumber === 1 && btnText === BTN_VALUE.continue && !prizesMoreData.length)" class="avator-change" :class="selNumber === 1 && btnText === BTN_VALUE.continue && 'translate-y-60'">
              <Lottie :jsonData="avatorImg"></Lottie>
              <div class="container-view">
                <UserAvatar :isWear="false" :isPreview="true" :NopreImage="false"  ref="avatorRef" class="change-view" :user="nowData || indrawData.filter(i => !i.batch_id)[0]?.user"></UserAvatar>
              </div>
              <div class="prize-name" v-if="selNumber === 1 && btnText === BTN_VALUE.continue">{{ nowData.name }}</div>
          </div>
          <el-scrollbar class="scroll-bar" ref="scrollRef" v-else>
              <div :class="['luck-userList', prizesMoreData.length > 6 && 'luck-and-more']">
                <div class="luck-user" v-for="item in prizesMoreData" :key="item.id">
                  <UserAvatar :isWear="false" :isPreview="true" :NopreImage="false" :user="item.user"></UserAvatar>
                  <span>{{ item.user.name }}</span>
                </div>
              </div>
          </el-scrollbar>
        </template>

        <!-- 抽取动态 -->
        <template v-else>
          <div :class="['scroll-container', nowData.length === 1 && 'unscroll']">
            <div v-if="btnText === BTN_VALUE.continue" :class="['scroll-header', nowData.length < 5 && 'jusctify-center']" :style="{transform: `translateX(${transformLeft}px)`}">
              <div class="luck-user-blog" v-for="(item, index) in nowData" :key="item.id">
                <UserAvatar
                  :class="selectIndex !== index && 'un-slect-avatar'"
                  v-bind="{
                    isWear: false,
                    needBorder: selectIndex === index,
                    isPreview: true,
                    NopreImage: false,
                    user: item.user
                  }"
                  @click="selectIndex = index"
                ></UserAvatar>
                <span>{{ item.user.name }}</span>
              </div>
            </div>
          </div>

          <el-scrollbar class="scroll-bar-blogs" ref="scrollRef" :class="!animation && 'single-blog'">
            <template v-if="animation">
              <el-carousel height="150px" :interval="160" :pause-on-hover="false" indicator-position="none" arrow="never">
                <el-carousel-item v-for="item in blogsData" :key="item">
                  <DynamicContent :dynamicData="item" :animation="animation"></DynamicContent>
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <DynamicContent :dynamicData="nowData[selectIndex]?.feed_info"></DynamicContent>
            </template>
          </el-scrollbar>
          <div v-if="btnText === BTN_VALUE.continue && nowData.length > 5 && prizeType === 'blog'" class="change-prize-left cursor-pointer" @click="handleTransform('left')"></div>
          <div v-if="btnText === BTN_VALUE.continue && nowData.length > 5 && prizeType === 'blog'" class="change-prize-right cursor-pointer" @click="handleTransform('right')"></div>
        </template>
      </slot>
    <div :class="['star-prize', 'cursor-pointer', {'disabled': (!prizeCondition.allNumber || !prizeCondition.allPeople)}]" v-if="prizeButton">
      <div class="prize-btn" @click="prizeHandle" v-loading="flag">{{ btnText }}</div>
    </div>
  </el-dialog>
</template>

<script>
import { computed, reactive, ref, toRefs, watch } from 'vue'
import avatorImg from '@/assets/img/lottie/avator/avator.json'
import { BTN_VALUE, DIALOGSTATE } from './prizeConst'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { prizeStart } from '@/apis/prize.js'
import DynamicContent from '../components/DynamicContent.vue'
import { deepClone } from '@/utils/tools'
import { dialogMsg } from '@/utils/dialogMsg.js'

export default {
  name: 'PrizeDialogs',
  components: {
    DynamicContent
  },
  emits: ['prizeSuccess', 'continuePrize'],
  props: {
    prizeButton: { // 是否显示抽奖按钮
      type: Boolean,
      default: true
    },
    btnValue: { // 按钮显示文字
      type: String,
      default: BTN_VALUE.start
    },
    className: { // dialog class name
      type: String,
      default: ''
    },
    indrawData: { // 参与人详情
      type: Array,
      default: () => []
    },
    prizeType: { // 抽奖类型 blog / avatar
      type: String,
      default: 'avatar'
    },
    prizeDialogVisible: { // 弹窗显示和隐藏
      type: Boolean
    },
    prize: { // 区分抽奖弹窗显示和隐藏
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    const avatorRef = ref(null)
    const scrollRef = ref(null)
    const store = useStore()
    const route = useRoute()
    const state = reactive({
      prizeButton: computed(() => props.prizeButton),
      prizeDialogVisible: computed(() => props.prizeDialogVisible), // 弹窗显示和隐藏
      userAvatorList: computed(() => props.indrawData), // 抽奖所有用户数据
      btnText: BTN_VALUE.pouse, // 显示文字
      animation: false, // 动画动作开始和结束
      timer: '', // 动画定时器ID
      nowData: '', // 当前滚动头像用户数据
      randomNumber: '', // 随机数 从某个人员开始抽
      selNumber: computed(() => store.state.prize.selNumber), // 每次抽取人数
      changeAnimations: '', // 动画开关 start stop
      prizeType: computed(() => props.prizeType), // 抽奖类型
      nowGift: computed(() => store.state.prize.nowGift), // 当前奖品数据
      prizeData: computed(() => store.state.prize.prizeData), // 中奖详情
      prizesMoreData: [], // 多人抽奖数据
      transAnimation: false,
      selectIndex: 0, // 微博选择id
      transformLeft: 0, // 平移位置
      blogsData: computed(() => {
        let data
        data = props.indrawData.filter(i => !i.is_prize_award && i.prize_weights > 0).map(i => i.feed_info)
        if (data.length < 4 && data.length !== 1) {
          data = [...data, ...data, ...data]
        }
        return data
      }), // 微博数据
      ruleID: computed(() => route.query?.ruleID || 1),
      prizeCondition: computed(() => store.state.prize.prizeCondition),
      flag: false
    })

    /**
     * 修改对应状态和文案
     */
    const changeState = (animation, btnText) => {
      state.animation = animation // 动画开始于结束
      state.btnText = btnText // 显示按钮文字
    }

    /**
     * 开始抽奖
     */
    const prizeHandle = () => {
      // 抽奖
      if (state.btnText === BTN_VALUE.pouse) {
        if (state.flag) return
        // 获取抽奖结果
        const prizeId = state.nowGift.id
        state.flag = true
        prizeStart({ rule_id: state.ruleID, prize_id: prizeId, nums: state.selNumber }).then(res => {
          if (res.code === 0) {
            // 设置抽奖ID为key 进行存储
            const storageData = { prizeId, value: res.data.lucky_users }
            store.commit('prize/setPrizeData', storageData)
            changeAnimationsMethod.stop()
            // 区分单个和多个 和动态 与头像 单个直接显示结果停止数据加载
            state.prizeType === 'avatar' ? methods.handleAvatarRes(res) : methods.handelBlogs(res)
            // 抽取成功，减少奖品数量
            const num = state.prizeCondition.allNumber - state.selNumber
            store.commit('prize/setPrizeConditions', { allNumber: num, allPeople: state.prizeCondition.allPeople })
          } else {
            dialogMsg('error', res.msg)
            changeAnimationsMethod.stop()
          }
        }).finally(() => {
          emit('prizeSuccess')
          state.flag = false
        })
      } else {
        if (!state.prizeCondition.allNumber) return
        if (!state.prizeCondition.allPeople) return
        changeAnimationsMethod.start()
        emit('continuePrize') // 继续抽奖 停止定时器任务
      }
    }

    const changeAnimationsMethod = { // 修改并记录动画状态
      stop () {
        state.changeAnimations = 'stop'
        changeState(false, BTN_VALUE.continue)
      },
      start () {
        clearInterval(state.timer)
        state.changeAnimations = 'start'
        changeState(true, BTN_VALUE.pouse)
      }
    }

    // 抽奖滚动动画函数
    const onAnimation = (type) => {
      if (type === 'start') {
        let lastPrizeData
        changeState(true, BTN_VALUE.pouse)
        if (state.prizeType === 'blog') {
          // 直接显示轮播图即可
          return false
        } else {
          lastPrizeData = deepClone(state.userAvatorList.filter(i => !i.is_award)).slice(0, 50)
        }
        console.log(lastPrizeData)
        if (lastPrizeData.length === 1) return (state.nowData = lastPrizeData[0]?.user)
        // 获取总长生成随机数
        state.randomNumber = Math.floor(Math.random() * (lastPrizeData.length + 1))
        console.log(state.randomNumber)
        state.timer = setInterval(() => {
          state.randomNumber === lastPrizeData.length && (state.randomNumber = 0)
          state.randomNumber++
          state.nowData = lastPrizeData[state.randomNumber]?.user
        }, 100)
      } else {
        changeState(false, BTN_VALUE.continue)
        // 停止动画
        if (state.timer) {
          clearInterval(state.timer)
          return (state.timer = null)
        }
      }
    }

    const methods = {
      handleAvatarRes (res) { // 抽取头像结果处理
        if (state.selNumber === 1) {
          state.prizesMoreData = []
          state.nowData = res.data.lucky_users[0]?.user
        } else {
          state.prizesMoreData = res.data.lucky_users
        }
      },
      handelBlogs (res) { // 抽取微博结果处理
        state.selectIndex = 0
        state.transformLeft = 0
        state.nowData = res.data.lucky_users
      },
      handleTransform (direct) { // 左右切换index
        const width = document.querySelector('.luck-user-blog').clientWidth
        if (direct === 'right') {
          if (state.selectIndex === state.nowData.length - 1) return
          state.transformLeft -= width
          state.selectIndex += 1
        } else {
          if (!state.selectIndex) return
          state.selectIndex -= 1
          if (state.transformLeft === 0) return
          state.transformLeft += width
        }
      }
    }
    watch(() => state.selectIndex, (value) => {
      console.log(value)
    })

    // 监听动画开始和结束
    watch(() => state.changeAnimations, (newValue) => {
      onAnimation(newValue)
    })

    watch(() => state.prizeDialogVisible, (visible) => {
      store.commit('prize/setMfOverlay', visible)
      if (!visible && props.prize) {
        // 关闭弹窗重置信息
        clearInterval(state.timer)
        changeState(false, BTN_VALUE.continue)
        changeAnimationsMethod.stop()
        state.timer = null
        state.prizesMoreData = []
        state.nowData = []
      }
    })

    return {
      ...toRefs(state),
      avatorImg,
      prizeHandle,
      BTN_VALUE,
      avatorRef,
      changeAnimationsMethod,
      ...methods,
      DIALOGSTATE,
      scrollRef
    }
  }
}
</script>
<style lang="less" scoped>
.prize-dialog {
  position: relative;
}
.prize-btn {
  font-weight: 500;
  font-size: 24px;
  width: 220px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  background: linear-gradient(180deg, #EEFAFF 0%, #FFFFFF 0.01%, #C8E8FF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  :deep(.el-loading-mask) {
    border-radius: 28px;
    background-color: #96caf6;
  }
}
.star-prize {
  position: absolute;
  left: 50%;
  bottom: 50px;
  transform: translateX(-50%);
  width: 220px;
  height: 56px;
  background-image: url('../../../../assets/img/prize/botton-normal.png');
  background-size: 100% 100%;
  background-size: cover;
  background-position: center 0;
  z-index: 200;
  &:hover {
    background-image: url('../../../../assets/img/prize/botton-hover.png');
    background-size: 100% 100%;
    background-size: cover;
    background-position: center 0;
  }
  &:active {
    background-image: url('../../../../assets/img/prize/botton-press.png');
    background-size: 100% 100%;
    background-size: cover;
    background-position: center 0;
  }
  &.disabled{
    filter: grayscale(100%);
    background-image: url('../../../../assets/img/prize/botton-normal.png') !important;
  }
}
:deep(.lottie){
  width: 280px !important;
  height: 280px !important;
  position: absolute;
  z-index: 2;
}
.avator-change {
  z-index: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transition: all .3s;
  margin-right: 110px;
  .prize-name {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    margin-top: 150px;
    font-size: 32px;
    color: #E8E8E8;
  }
}
.avator-wrapper {
  height: 268px !important;
}
.container-view {
  width: 160px;
  height: 160px;
  border-radius: 80px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  :deep(.user-avatar) {
    margin-right: 0 !important;
  }
  :deep(.user-avatar .el-image) {
    width: 200px !important;
    height: 200px !important;
    font-size: 18px !important;
  }
  :deep(.user-avatar p) {
    width: 200px !important;
    height: 200px !important;
    font-size: 36px !important;
    line-height: 200px !important;
  }
}
.clone-user {
  position: absolute;
  z-index: -1;
  :deep(& .el-image),
  :deep(& p) {
    width: 160px !important;
    height: 160px !important;
    line-height: 160px;
    font-size: 18px !important;
  }
}

.trans-animation {
  :deep(& .el-image),
  :deep(& p) {
    width: 160px !important;
    height: 160px !important;
    line-height: 160px !important;
    font-size: 18px;
  }
  animation: trans-ani 3s;
}

@keyframes trans-ani {
  0% {
    width: 160px;
    height: 160px;
    line-height: 160px;
    font-size: 18px;
    left: 0;
    top: 0;
  }
  100% {
    width: 90px !important;
    height: 90px !important;
    line-height: 90px !important;
    font-size: 16px;
    left: v-bind(left);
    top: v-bind(top);
  }
}

:deep(.user-avatar) {
  margin-right: 0;
}
.avator-list {
  // display: flex;
}
.luck-userList {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  :deep(.luck-user) {
    min-width: 16.66% !important;
    height: 140px;
    margin-top: 40px;
    &:nth-child(-n + 6) {
      margin-top: 0;
    }
    span {
      display: block;
      text-align: center;
      margin-top: 12px;
      font-size: 16px;
      color: #E8E8E8;
    }
    p, .el-image {
      width: 90px !important;
      height: 90px !important;
      line-height: 90px !important;
    }
  }
}
.transform-top {
  transform: translateY(40px);
  transition: all 1s;
}

// 微博样式
.prize.blog.prize-dialog {
  .star-prize {
    bottom: -116px !important;
  }
}
.scroll-container {
  position: absolute;
  top: -200px;
  left: 50%;
  transform: translateX(-50%);
  width: 796px;
  height: 140px;
  overflow: hidden;
}
  // 头部滑动区域
  .scroll-header {
    position: absolute;
    transition: all .3s;
    width: 100%;
    height: 140px;
    top: 0;
    left: 0;
    display: flex;
    & .luck-user {
      min-width: 20%;
      margin-top: 0;
    }
    .user-avatar {
      margin-right: 0;
    }
  }
  .jusctify-center {
    justify-content: center;
  }
:deep(.luck-user-blog) {
  min-width: 20% !important;
  height: 140px;
  span {
    display: block;
    text-align: center;
    margin-top: 12px;
    font-size: 16px;
    color: #E8E8E8;
  }
  p, .el-image {
    width: 90px !important;
    height: 90px !important;
    line-height: 90px !important;
  }
}
.un-slect-avatar {
  opacity: .4;
}
:deep(.dynamic-box .blog-message .blog-content .blogContent-img .el-image > img) {
  max-height: 320px !important;
}
.change-prize-left,
.change-prize-right {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.change-prize-left {
  left: -147px;
}
.change-prize-right {
  right: -147px;
}
.change-prize-left {
  background: url('../../../../assets/img/prize/add-left-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-left-hover.svg');
    background-size: 100% 100%;
  }
}

.change-prize-right {
  background: url('../../../../assets/img/prize/add-right-normal.svg');
  background-size: 100% 100%;
  &:hover {
    background: url('../../../../assets/img/prize/add-right-hover.svg');
    background-size: 100% 100%;
  }
}
.scroll-bar-blogs {
  border-radius: 20px;
  overflow: hidden;
  :deep(.el-scrollbar__view) {
    width: 100%;
    height: 100% !important;
    border-radius: 20px;
    .el-carousel, .el-carousel__container {
      height: 100% !important;
      background: linear-gradient(180deg, #FFFFFF 0%, #E6F0FF 100%);
      .blog-message {
        background: none;
      }
      .el-carousel__item {
        // border-right: 2px solid #000 !important;
      }
    }
  }
}
.single-blog {
  background: linear-gradient(180deg, #FFFFFF 0%, #E6F0FF 100%) !important;
  :deep(.blog-message) {
    background: none;
  }
}
.translate-y-60 {
  transform: translateY(-60px);
}
.unscroll {
  overflow: initial;
}
</style>

<style lang="less">
.el-dialog.prize-dialog {
  .el-dialog__body {
    position: relative;
  }
  .scroll-bar {
    height: 500px !important;
    .el-scrollbar__view {
      width: 100%;
      height: 100% !important;
    }
    :deep(.el-scrollbar__wrap) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .luck-userList {
      justify-content: center;
      padding-right: 110px;
    }
    .luck-userList.luck-and-more {
      height: auto;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}

.screen .el-overlay {
  background: rgba(0,0,0,.9);
}

</style>
