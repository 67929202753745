export const BTN_VALUE = {
  start: '开始抽奖',
  pouse: '停止',
  continue: '继续抽奖'
}
export const DIALOGSTATE = {
  inDraw: 0, // 参与人
  awardList: 1, // 中奖人
  prize: 2 // 抽奖
}

export const DIALOGSTATECHO = {
  0: 'inDraw',
  1: 'awardList',
  2: 'prize'
}
