<!--
 * @Author: 何琦 heqi@imyfone.cn
 * @Date: 2023-04-12 16:14:21
 * @LastEditors: liujx@imyfone.cn
 * @LastEditTime: 2024-06-25 15:08:24
 * @FilePath: \mfadmind:\hq\mfzj\src\views\layout\prize\index.vue
 * @Description: 抽奖大屏页面
-->
<template>
  <div class="price-page">
    <canvas id="prize-bg"></canvas>
    <div class="popContainer" v-if="mfOverlay"></div>
    <Vscreensize>
      <div class="screen" id="screen" v-if="prizeData.title">
        <div class="screen-container">
          <div class="prize-wrapper">
            <div class="left-card">
              <div class="prize-logo">
                <img class="logo" src="@/assets/img/prize/iMyFone_logo.svg" />
                <span class="time">{{time}}</span>
                <span class="title"></span>
              </div>
              <div class="left prize-line-left">
                <LeftPanel
                  ref="leftPanelRef"
                  @showAllInDrawDialog="showAllInDrawDialog"
                  @changeAward="changeAward"
                  @refresh="refresh"
                  v-bind="{ inDrowNumber:indrawData.remain_users_num, prizeData }"
                ></LeftPanel>
              </div>
            </div>
            <div class="right-card">
              <div class="prize-title oneline-overflow">
                <div class="text-liner">{{ prizeData.title }}</div>
              </div>
              <div class="right prize-line-right">
                <RightPanelCard ref="rightPanelRef" v-bind="{prizeType: prizeData.is_tick_feed ? 'blog' : 'avatar'}"></RightPanelCard>
                <div class="prize-button cursor-pointer star-prize">
                  <div class="text-liner" @click="startPrize">{{ btnValue }}</div>
                </div>
                <div class="star-bg"><Lottie :jsonData="starBg"></Lottie></div>
                <div v-if="voiceVisible" class="side-icon side-icon-voice cursor-pointer" @click="voiceHandle('start', prizeData.bg_music)"></div>
                <div v-else class="side-icon side-icon-close-voice cursor-pointer" :class="!prizeData.bg_music && 'disabled'" @click="voiceHandle('end', prizeData.bg_music)"></div>
                <div class="side-icon side-icon-price cursor-pointer" @click="showAwardList"></div>
              </div>
            </div>
          </div>
        </div>
        <PrizeDialog ref="prizeDialogRef"
          v-model="prizeDialogVisible"
          v-bind="{
            prizeButton: prizeButtonVisible,
            className: `${DIALOGSTATECHO[dialogState]}`,
            indrawData: indrawData.data,
            prizeDialogVisible,
            prize: true,
            prizeType: prizeData.is_tick_feed ? 'blog' : 'avatar'
          }"
          @prizeSuccess="prizeSuccess"
          @continuePrize="clearReServe"
          >
          <template v-if="dialogState !== DIALOGSTATE.prize" #contents>
            <PrizeUserList v-bind="{roleListData, roleType: dialogState, posterBtn }"></PrizeUserList>
          </template>
        </PrizeDialog>
        <audio v-show="false" :src="prizeData.bg_music" loop ref="audioRef"></audio>
      </div>
    </Vscreensize>
  </div>
</template>

<script>
import { reactive, toRefs, ref, nextTick, onMounted, computed, onUnmounted, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { getRuleInfo, getPrizeJoinUser, getLuckyUsers } from '@/apis/prize.js'
import { RESPOND } from '@/utils/const.js'
import { DIALOGSTATE, DIALOGSTATECHO, BTN_VALUE } from './components/prizeConst'
import { getPrizeTime, preloadImgs } from '@/utils/tools.js'
import { dialogMsg } from '@/utils/dialogMsg.js'
import LeftPanel from './components/LeftPanel.vue'
import RightPanelCard from './components/RightPanelCard.vue'
import starBg from '@/assets/img/lottie/right_bg/right_bg.json'
import PrizeDialog from './components/PrizeDialogs.vue'
import PrizeUserList from './components/PrizeUserList.vue'
import { setBg } from '@/utils/screen/screenBg'
import Vscreensize from '@/utils/screen/Vscreensize.js'
import { DeletePending } from '@/utils/cancelRequest'

export default {
  components: {
    LeftPanel,
    RightPanelCard,
    PrizeDialog,
    PrizeUserList,
    Vscreensize
  },
  setup (props, emit) {
    console.log(emit)
    console.log(RESPOND)
    const route = useRoute()
    const store = useStore()
    const audioRef = ref(null)
    const prizeDialogRef = ref(null)
    const leftPanelRef = ref(null)
    const rightPanelRef = ref(null)
    const pageSize = {
      page: 1,
      limit: 500
    }
    const state = reactive({
      time: getPrizeTime(),
      voiceVisible: false, // 声音开关
      prizeDialogVisible: false, // 抽奖 参与人 中奖名单 弹窗
      ruleID: route.query.ruleID || 1, // 抽奖详情ID
      dialogState: DIALOGSTATE.prize, // 弹窗类型 抽奖 参与人 中奖名单 弹窗
      prizeButtonVisible: false, // 是否显示弹窗内按钮
      prizeData: {}, // 抽奖详情
      roleListData: {}, // 弹窗显示头像数据
      indrawData: {

      }, // 参与人详情
      luckyData: {}, // 中奖数据
      posterBtn: false, // 海报按钮
      btnValue: computed(() => rightPanelRef.value?.showLuckUserData?.length ? BTN_VALUE.continue : BTN_VALUE.start), // 按钮显示文案
      mfOverlay: computed(() => store.state.prize.mfOverlay),
      prizeCondition: computed(() => store.state.prize.prizeCondition), // 按钮状态
      nowGift: computed(() => store.state.prize.nowGift), // 当前奖品数据
      timer: null
    })
    // 时间
    setInterval(function () {
      state.time = getPrizeTime()
    }, 1000)

    const requestFn = () => {
      const params = { rule_id: state.ruleID }
      const request = [getRuleInfo(params), getLuckyUsers(params)]
      return Promise.all(request).then((res) => {
        res.map((i, index) => {
          if (i.code === RESPOND.SUCCESS) {
            if (index === 0) {
              state.prizeData = i.data
              store.commit('prize/setPrizelist', i.data?.prize_list.map((i, index) => { // 获取奖品详情
                i.awardIndex = index
                return i
              }))
              pageSize.prize_id = state.nowGift?.id || i.data.prize_list[0].id
              state.timer && clearInterval(state.timer)
              methods.joinReq({ ...pageSize, ...params })
              // methods.joinReq({ ...pageSize, ...params }).then(() => {
              //   i.data.is_tick_feed && methods.reServe(methods.joinReq, { ...pageSize, ...params })
              // })
            } else {
              state.luckyData = i.data.prize_list
              store.commit('prize/setLuckyUser', i.data.prize_list)
            }
          }
        })
      })
    }

    const methods = {
      // 统一处理 参与人，中奖名单 弹窗数据以及显示隐藏 抽奖按钮 生成海报按钮
      changeDialogData (dialogState, roleListData, prizeDialogVisible, prizeButtonVisible = false, posterBtn = false) {
        state.dialogState = dialogState
        state.roleListData = roleListData
        state.prizeDialogVisible = prizeDialogVisible
        state.prizeButtonVisible = prizeButtonVisible
        state.posterBtn = posterBtn
      },
      startPrize () { // 开始抽奖处理事件
        if (!leftPanelRef.value.lastNumber) return dialogMsg('warning', '当前奖品数量剩余0', document.querySelector('.right'))
        if (!leftPanelRef.value.allPeople) return dialogMsg('warning', '可参与人数为0', document.querySelector('.right'))
        methods.changeDialogData(DIALOGSTATE.prize, {}, true, true)
        nextTick(() => {
          methods.clearReServe()
          prizeDialogRef.value.changeAnimationsMethod.start()
        })
      },
      showAllInDrawDialog () { // 显示参与人
        methods.changeDialogData(DIALOGSTATE.inDraw, state.indrawData.data, true, false, false)
      },
      voiceHandle (type, audio) { // 音频控制
        if (!audio) return
        type === 'start' ? audioRef.value.pause() : audioRef.value.play()
        state.voiceVisible = !state.voiceVisible
      },
      showAwardList () { // 中奖名单
        methods.changeDialogData(DIALOGSTATE.awardList, state.luckyData, true, false, true)
      },
      prizeSuccess () { // 关闭弹窗回调事件 更新中奖名单
        requestFn()
      },
      changeAward () { // 切换奖项
        state.timer && clearInterval(state.timer)
        state.requestInit = false
        pageSize.prize_id = state.nowGift?.id
        DeletePending()
        methods.joinReq(Object.assign({ rule_id: state.ruleID }, pageSize))
        // methods.joinReq(Object.assign({ rule_id: state.ruleID }, pageSize)).then(() => {
        //   state.prizeData?.is_tick_feed && methods.reServe(methods.joinReq, Object.assign({ rule_id: state.ruleID }, pageSize))
        // })
      },
      joinReq (params) { // 参与人详情
        return getPrizeJoinUser(params).then(({ data, code }) => {
          if (code === RESPOND.SUCCESS) { // 接口返回成功判断
            state.indrawData = data // 参与人详情
            const arr = data.data.filter(i => i.batch_id && i.user?.avatar.length)
            preloadImgs(arr.map(i => i.user.avatar))
          } else {
            state.indrawData = {
              data: [],
              remain_users_num: 0
            }
          }
        }).catch(() => {
          state.indrawData = {
            data: [],
            remain_users_num: 0
          }
        })
      },
      reServe (fn, reqData) { // 参与人数实时更新
        state.timer && clearInterval(state.timer)
        state.timer = setInterval(() => {
          fn(reqData)
        }, 5000)
      },
      clearReServe () {
        state.timer && clearInterval(state.timer)
      },
      refresh () {
        methods.joinReq(Object.assign({ rule_id: state.ruleID }, pageSize))
      }
    }

    watch(() => state.prizeDialogVisible, (value) => {
      !value && methods.changeAward()
    })

    const closeDialog = (e) => {
      if (e.target.className === 'popContainer') {
        state.prizeDialogVisible = false
        methods.changeAward()
      }
    }

    onMounted(() => {
      requestFn().then(() => setBg(document.querySelector('#prize-bg')))
      window.addEventListener('click', closeDialog)
    })

    onUnmounted(() => {
      window.removeEventListener('click', closeDialog)
      state.time && clearInterval(state.time)
      state.timer && clearInterval(state.timer)
    })

    return {
      ...toRefs(state),
      starBg,
      ...methods,
      DIALOGSTATE,
      DIALOGSTATECHO,
      audioRef,
      prizeDialogRef,
      leftPanelRef,
      rightPanelRef
    }
  }
}
</script>

<style lang="less" scoped>

@import url(../../../assets/less/prize.less);
body,
html,
#app,
.screen {
  width: 100%;
  height: 100%;
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
}
.screen {
  position: fixed;
}

#prize-bg {
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: 1;
  position: fixed
}

.screen-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  padding: 100px 100px 96px 50px;
}
.prize-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .left-card {
    width: 500px;
    margin-right: 50px;
  }
  .right-card {
    width: 100%;
  }
  .left-card, .right-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .left, .right {
      height: 100%;
    }
    .left {
      padding: 40px 52px;
    }
    .right {
      position: relative;
      overflow: inherit;
      :deep(.van-popup--center) {
        top: 40%;
      }
      :deep(.van-popup) {
        position: absolute;
      }
    }
  }
  .right-card {
    flex: 1;
  }
}
.star-bg {
  position: absolute;
  bottom: 40px;
  width: 100%;
}
.star-bg .lottie {
  width: 100% !important;
  height: 100% !important;
}
.prize-button {
  z-index: 10;
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 26px;
  width: 220px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  color: #fff;
  div {
    background: linear-gradient(180deg, #EEFAFF 0%, #FFFFFF 0.01%, #C8E8FF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
}
.star-prize {
  width: 220px;
  height: 56px;
  background-image: url('../../../assets/img/prize/botton-normal.png');
  background-size: 100% 100%;
  background-size: cover;
  background-position: center 0;
  font-size: 24px;
  &:hover {
    background-image: url('../../../assets/img/prize/botton-hover.png');
    background-size: 100% 100%;
    background-size: cover;
    background-position: center 0;
  }
  &:active {
    background-image: url('../../../assets/img/prize/botton-press.png');
    background-size: 100% 100%;
    background-size: cover;
    background-position: center 0;
  }
}
.side-icon {
  position: absolute;
  right: -100px;
  top: 36px;
  width: 90px;
  height: 74px;
}
.side-icon-voice {
  background-image: url('../../../assets/img/prize/icon_voice.png');
  background-size: 100% 100%;
  &:hover {
    background-image: url('../../../assets/img/prize/icon_voice_hover.png');
    background-size: 100% 100%;
  }
}
.side-icon-close-voice {
  background-image: url('../../../assets/img/prize/icon_close_voice.png');
  background-size: 100% 100%;
  &:hover {
    background-image: url('../../../assets/img/prize/icon_close_voice_hover.png');
    background-size: 100% 100%;
  }
}
.side-icon-price {
  top: 142px;
  background-image: url('../../../assets/img/prize/icon_side.png');
  background-size: 100% 100%;
  &:hover {
    background-image: url('../../../assets/img/prize/icon_side_hover.png');
    background-size: 100% 100%;
  }
}
:deep(.el-dialog.prize-dialog) {
  background: transparent;
  border-radius: 30px;
  width: 1180px !important;
  height: 776px;
  background-image: url('../../../assets/img/prize/prize_dialog_bg.png');
  background-size: 100% 100%;
  .el-dialog__header {
    padding: 0;
    .el-dialog__headerbtn {
      top: 28px;
      right: 30px;
      .el-icon {
        font-size: 30px;
        font-weight: 700;
        color: rgba(255, 255, 255, .4);
        z-index: 100;
      }
    }
  }
  .el-dialog__body {
    height: 100%;
    padding: 68px 5px 130px 110px;
    .user-content li {
      width: calc((100% - 105px) / 6);
      margin: 0;
      margin-top: 50px;
      & .el-image,
      .user-avatar p:first-child {
        width: 90px !important;
        height: 90px !important;
        line-height: 90px !important;
      }
      .is-get {
        width: 90px !important;
        height: 90px !important;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
      }
      &:nth-child(-n + 6) {
        margin-top: 0;
      }
    }
  }
}
:deep(.prize.blog.prize-dialog) {
  width: 1084px !important;
  height: 600px !important;
  margin-top: 290px !important;
  background-image: url(../../../assets/img/prize/dynamic-bg.png);
  padding: 20px;
  .el-dialog__body {
    background: #fff;
    border-radius: 20px;
    padding: 0;
    position: relative;
  }
  .el-dialog__header .el-dialog__headerbtn {
    top: -130px;
    right: -130px;
    .el-icon {
      font-size: 50px;
    }
  }
}

// 参与名单内容
:deep(.el-dialog.prize-dialog.inDraw) {
  position: relative;
  &::after{
    content: '';
    position: absolute;
    left: 0px;
    bottom: 2px;
    width: 100%;
    height: 92px;
    z-index: 1;
    pointer-events: none;
    background: url(../../../assets/img/prize/footer-mask.png) no-repeat;
  }
  .scrollbar-content{
    height: 610px
  }
  .user-content{
    margin-bottom: 50px;
  }
}

// 中奖名单内容
:deep(.el-dialog.prize-dialog.awardList) {
  position: relative;
  &::after{
    content: '';
    position: absolute;
    left: 0px;
    bottom: 2px;
    width: 100%;
    height: 92px;
    z-index: 1;
    pointer-events: none;
    background: url(../../../assets/img/prize/footer-mask.png) no-repeat;
  }
  .scrollbar-content{
    height: 625px
  }
  .prize-list{
    margin-bottom: 50px;
  }
}

</style>
<style>
#app {
  overflow: hidden !important;
}
.v-screen-box::before,
.v-screen-box::after{
  content: '';
  position: absolute;
}
.v-screen-box::before {
  width: 800px;
  height: 642px;
  top: 0;
  left: 0;
  background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/bg-left.png) no-repeat top left/100% 100%;
}
.v-screen-box::after {
  width: 700px;
  height: 675px;
  bottom: 0;
  right: 0;
  background: url(https://static-sns.imyfone.cn/uploads/files/2023/prize/bg-right.png) no-repeat bottom right/100% 100%;
}
div.popContainer {
  z-index: 1;
  position: absolute;
  top: 0;left: 0;
  right: 0;bottom: 0;
  background: rgba(0, 0, 0);
}

</style>
