/*
 * @Descripttion: 抽奖Api
 * @version: 1.0.0
 * @Author: tangjz
 * @Date: 2023-03-31 10:50:15
 * @LastEditors: tangjz
 * @LastEditTime: 2023-05-19 18:05:05
 */

import { get, post } from '@/apis/method'

const getLuckyUsers = (data) => get('/api/prize/lucky_users', data) // 获取中奖名单
const setPrizeRule = (data) => post('/api/prize/set_rule', data) // 设置抽奖规则
const getRuleInfo = (data) => get('/api/prize/rule_info', data) // 获取抽奖规则
const getPrizeJoinUser = (data) => get('/api/prize/join_users', data) // 参与用户列表
const sendPoster = (data) => get('/api/prize/send_poster', data) // 生成海报
const prizeStart = (data) => post('/api/prize/start', data) // 开始抽奖
const getDeptInfo = (data) => get('/api/prize/dept_info', data) // 中奖用户列表
const getPrizeTopic = (data) => get('/api/feeds/feed_wall_config', data) // 动态详情

export {
  getLuckyUsers,
  setPrizeRule,
  getRuleInfo,
  getPrizeJoinUser,
  sendPoster,
  prizeStart,
  getDeptInfo,
  getPrizeTopic
}
