<!--
  * @Descripttion: 参与和中奖名单弹窗
  * @version: 1.0.0
  * @Author: 何琦
  * @Date: 2023-03-31 10:39:13
 * @LastEditors: tangjz
 * @LastEditTime: 2023-05-30 14:14:36
 -->
 <template>
    <div class="content">
      <div class="top-content">
        <p :class="['title', !!roleType ? 'is-prize': '']">{{roleType === 0 ? '参与':'中奖'}}名单</p>
        <el-button v-if="posterBtn" type="primary" class="go-poster" @click="toCreatePoster()">生成海报</el-button>
      </div>
      <div class="scrollbar-content">
        <el-scrollbar>
          <template v-if="!roleType">
            <ul class="user-content">
              <li v-for="item in roleListData" :key="item.id">
                <UserAvatar
                  :user="item.user"
                  size="96"
                  fontSize="32"
                  :isPreview="true"
                  :NopreImage="false"
                  :isWear="false"
                >
                <template #slot>
                  <p class="user-name">{{item.user.name}}</p>
                </template>
              </UserAvatar>
              <p v-if="!!item.is_award" class="is-get">已中奖</p>
              </li>
            </ul>
          </template>
          <template v-else-if="!!roleType">
            <div v-for="item in roleListData" :key="item.id" class="prize-list">
              <p class="prize-name">{{ item.title }} <span v-if="item.award_name">: {{item.award_name }}</span></p>
              <ul class="user-content" v-if="item.lucky_users.length">
                <li v-for="luckyItem in item.lucky_users" :key="luckyItem.id">
                  <UserAvatar
                    :user="luckyItem.user"
                    size="96"
                    fontSize="32"
                    :isPreview="true"
                    :NopreImage="false"
                    :isWear="false"
                  >
                  <template #slot>
                    <p class="user-name">{{luckyItem.user.name}}</p>
                  </template>
                </UserAvatar>
                </li>
              </ul>
              <p v-if="!item.lucky_users.length" class="no-user">暂无</p>
            </div>

          </template>
        </el-scrollbar>
      </div>
    </div>
</template>
<script>
import { reactive, toRefs, onMounted, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { sendPoster } from '@/apis/prize.js'
import { RESPOND } from '@/utils/const.js'
import { dialogMsg } from '@/utils/dialogMsg.js'

export default {
  name: 'PrizeUserList',
  components: {
  },
  props: {
    posterBtn: { // 生成海报按钮
      type: Boolean,
      default: false
    },
    roleListData: {
      type: Array,
      default: () => []
    },
    roleType: {
      type: Number, // 参与-0, 中奖-1
      default: 0
    }
  },
  setup (props, { emit }) {
    const state = reactive({
      roleListData: computed(() => props.roleListData),
      ruleID: computed(() => route.query.ruleID)
    })
    onMounted(() => {
    })
    const route = useRoute()
    const router = useRouter()
    // 跳转海报页面
    const toCreatePoster = () => {
      sendPoster({ rule_id: state.ruleID || 4 }).then(res => {
        if (res.code === RESPOND.SUCCESS) {
          const { href } = router.resolve({
            name: 'prizePoster',
            query: {
              ruleID: state.ruleID
            }
          })
          window.open(href, '_blank')
        } else {
          dialogMsg('error', res.msg)
        }
      })
    }
    return {
      ...toRefs(state),
      toCreatePoster
    }
  }
}

</script>
<style lang="less" scoped>
    .el-scrollBar {
      height: 614px
    }
    .content {
      height: 100%;
    }
    .scrollbar-content{
      height: 614px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: 90%;
        z-index: 10;
        pointer-events: none;
      }
      &::before{
        top: -10px;
        height: 20px;
        background: linear-gradient(0deg, transparent 20%, #151922 90%);
      }
    }
    .title{
      font-weight: 600;
      font-size: 32px;
      line-height: 45px;
      text-align: center;
      background: linear-gradient(180deg, #EEFAFF 0%, #7DB1FF 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      margin: 0 105px 50px 0;
      &.is-prize{
        margin-bottom: 32px;
      }
    }
    .close{
      position: absolute;
      top: 28px;
      right: 28px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
    .go-poster{
      position: absolute;
      top: 70px;
      width: 100px;
      height: 40px;
      background: #207CFB;
      box-shadow: 8px 8px 25px rgba(32, 124, 251, 0.4);
      border-radius: 8px;

    }
    .user-content{
      display: flex;
      flex-wrap: wrap;
      min-height: 40px;
      li{
        position: relative;
        margin: 0 34px 56px;
        .user-avatar{
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 0;
          .user-name{
            margin-top: 12px;
            font-size: 16px;
            line-height: 22px;
            color: #E8E8E8;
          }
        }
        .is-get{
          position: absolute;
          left: 0;
          top: 0;
          width: 96px;
          height: 96px;
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
          border-radius: 50%;
          font-weight: 500;
          font-size: 16px;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    .no-user{
      font-size: 18px;
      line-height: 32px;
      color: #E8E8E8;
    }
    .prize-list{
      .prize-name{
        position: relative;
        font-weight: 600;
        font-size: 20px;
        line-height: 32px;
        background: linear-gradient(180deg, #EEFAFF 0%, #7DB1FF 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        margin-bottom: 33px;
        &::before{
          content: '';
          position: absolute;
          bottom: -6px;
          left: 0;
          width: 40px;
          height: 2px;
          background: #207CFB;
        }
      }
    }
</style>
